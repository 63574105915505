<template>
  <div v-if="Object.keys(oss).length">
    <b-tabs
      v-model="activeMainTab"
      class="preview-tabs"
      justified
      pills
    >
      <b-tab
        v-for="(os, osIndex) in oss"
        :key="os.title"
        :title="os.title"
      >
        <b-tabs
          v-if="Object.keys(os.browsers).length"
          v-model="activeNestedTabs[osIndex]"
          align="center"
        >
          <b-tab
            v-for="(browser, idx) in os.browsers"
            :key="`${os.title}-${browser.title}`"
            :title="browser.title"
          >
            {{ componentName(osIndex, idx) }}
            <component
              :is="getComponentOrFallback(osIndex, idx)"
              :key="componentKey(osIndex, idx)"
              :preview-data="{
                site_name: webpush.site_name,
                text: defaultText,
                title: defaultTitle,
                icon: defaultIcon,
                image: defaultImage
              }"
            />
          </b-tab>
        </b-tabs>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AndroidChrome from './preview/AndroidChrome.vue'
import AndroidOpera from './preview/AndroidOpera.vue'
import AndroidYandex from './preview/AndroidYandex.vue'

export default {
  components: {
    BTabs,
    BTab,
    AndroidChrome,
    AndroidOpera,
    AndroidYandex,
  },
  props: {
    webpush: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeMainTab: 0,
      activeNestedTabs: {},
      oss: [
        {
          title: 'Android',
          key: 'android',
          browsers: [
            { title: 'Chrome' },
            { title: 'Opera' },
            { title: 'Firefox' },
            { title: 'Yandex' },
          ],
        },
        {
          title: 'Windows 7',
          key: 'windows7',
          browsers: [
            { title: 'Chrome' },
            { title: 'Opera' },
            { title: 'Firefox' },
            { title: 'Yandex' },
          ],
        },
        {
          title: 'Windows 10',
          key: 'windows10',
          browsers: [
            { title: 'Chrome' },
            { title: 'Opera' },
            { title: 'Firefox' },
            { title: 'Yandex' },
          ],
        },
        {
          title: 'MacOs',
          key: 'macos',
          browsers: [
            { title: 'Chrome' },
            { title: 'Opera' },
            { title: 'Firefox' },
            { title: 'Yandex' },
            { title: 'Safari' },
          ],
        },
      ],
      // eslint-disable-next-line global-require
      defaultImg: require('@/assets/images/webpush/marketing.png'),
      componentMap: {
        'android-chrome': 'AndroidChrome',
        'android-opera': 'AndroidOpera',
        'android-firefox': 'AndroidFirefox',
        'android-yandex': 'AndroidYandex',
        'windows7-chrome': 'Windows7Chrome',
        'windows7-opera': 'Windows7Opera',
        'windows7-firefox': 'Windows7Firefox',
        'windows7-yandex': 'Windows7Yandex',
        'windows10-chrome': 'Windows10Chrome',
        'windows10-opera': 'Windows10Opera',
        'windows10-firefox': 'Windows10Firefox',
        'windows10-yandex': 'Windows10Yandex',
        'macos-chrome': 'MacosChrome',
        'macos-opera': 'MacosOpera',
        'macos-firefox': 'MacosFirefox',
        'macos-yandex': 'MacosYandex',
        'macos-safari': 'MacosSafari',
      },
    }
  },
  computed: {
    defaultTitle() {
      return this.webpush.title || 'An example of notification'
    },
    defaultText() {
      return this.webpush.message || 'An example of notification'
    },
    defaultIcon() {
      return this.webpush.icon_url || this.defaultImg
    },
    defaultImage() {
      return this.webpush.image_url || this.defaultImg
    },
  },
  watch: {
    activeMainTab(newVal) {
      if (!(newVal in this.activeNestedTabs)) {
        this.syncNestedTab(newVal)
      }
    },
  },
  created() {
    this.oss.forEach((_, index) => {
      if (!(index in this.activeNestedTabs)) {
        this.$set(this.activeNestedTabs, index, 0)
      }
    })
  },
  methods: {
    componentName(osIndex, browserIndex) {
      const mainTab = this.oss[osIndex]
      return `${mainTab.key}-${mainTab.browsers[browserIndex].title}`.toLowerCase()
    },
    getComponentOrFallback(osIndex, browserIndex) {
      const componentName = this.componentName(osIndex, browserIndex)
      return this.$options.components[this.componentMap[componentName]] || null
    },
    syncNestedTab(newMainTab) {
      const oldBrowsers = this.oss[this.activeMainTab].browsers
      const newBrowsers = this.oss[newMainTab].browsers
      const currentBrowser = oldBrowsers[this.activeNestedTabs[this.activeMainTab]].title

      const newActiveNestedTab = newBrowsers.findIndex(browser => browser.title === currentBrowser)
      this.activeNestedTabs[newMainTab] = newActiveNestedTab !== -1 ? newActiveNestedTab : 0
    },
    componentKey(osIndex, browserIndex) {
      console.log(`${osIndex}-${browserIndex}-${this.activeMainTab}-${this.activeNestedTabs[osIndex]}`)
      return `${osIndex}-${browserIndex}-${this.activeMainTab}-${this.activeNestedTabs[osIndex]}`
    },
  },
}
</script>

<style scoped>
  .preview-tabs {
    background-color: aliceblue;
    min-height: 320px;
  }
</style>
